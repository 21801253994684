import type { StoryblokContent } from '../storyblok'
import type { EdgeStyles } from './shared'
import type Storyblok from '~/types/vendors/storyblok'
import type { Category } from '~/utils/categories/normalizeCategories'
import consola from 'consola'
import { z } from 'zod'
import { EDGE_STYLES } from './shared'

const GRID_TYPES = ['product-grid-small', 'product-grid-large'] as const

type GridType = (typeof GRID_TYPES)[number]

export interface ProductListBlockContent extends StoryblokContent {
  component: 'product-list-block'
  title: Storyblok.Text
  label: Storyblok.Text
  link: {
    id: string
    url: string
    linktype: string
    fieldtype: string
    cached_url: string
  }
  ctaBackgroundColor: string
  ctaTextColor: string
  category: {
    _uid: string
    category: string // uuid of category Story
    component: string
    _editable: string
  }[]
  gridType: {
    _uid: string
    component: GridType
    numberOfRows: string
    _editable: string
  }[]
  edgeStyle: EdgeStyles
  xo_tag?: string // Overwrites the category
  xo_variables?: string // Comma separated list of variables in the format key:value, overrides the category variables
  distributions_count: string
}
const WidgetVariables = z.map(z.string(), z.string()).optional().describe('Variables to be used in the widget')
type WidgetVariables = z.infer<typeof WidgetVariables>

const ProductListBlock = z.object({
  uid: z.string(),
  title: z.string(),
  label: z.string(),
  link: z.object({
    id: z.string(),
    cached_url: z.string(),
  }),
  ctaBackgroundColor: z.string(),
  ctaTextColor: z.string(),
  category: z.string().optional().describe('The id of a category'),
  categoryTag: z.string().optional().describe('Overwrites the category id'),
  distributionsCount: z.coerce.number().default(1).describe('The number of distributions to show in the block'),
  widgetVariables: WidgetVariables,
  gridType: z.enum(GRID_TYPES).default('product-grid-small'),
  gridRows: z.coerce.number().default(1),
  edgeStyle: z.enum(EDGE_STYLES),
})

export type ProductListBlock = z.infer<typeof ProductListBlock>

function mapWidgetVariables(variablesString: string): WidgetVariables {
  const map = new Map<string, string>()
  variablesString.split(',').forEach((variable) => {
    const [key, value] = variable.split(':')
    map.set(key, value)
  })

  return map
}

export function normalizeProductListBlock(
  schema: ProductListBlockContent,
  categories?: Map<string, Category>,
): ProductListBlock {
  const category = schema.category?.[0] && categories && categories.get(schema.category[0].category)

  const mappedWidgetVariables = schema.xo_variables ? mapWidgetVariables(schema.xo_variables) : undefined

  const result = ProductListBlock.safeParse({
    ...schema,
    uid: schema._uid,
    category: category?.content.id,
    edgeStyle: schema.edgeStyle || 'none',
    categoryTag: schema.xo_tag,
    distributionsCount: schema.distributions_count,
    widgetVariables: mappedWidgetVariables,
    gridType: schema.gridType[0].component,
    gridRows: schema.gridType[0].numberOfRows,
  })

  if (result.success) {
    return result.data
  }
  else {
    const errors = Object.entries(result.error.format()).slice(1)
    consola.warn(`Problem with product list block: ${schema.title}. Issues: `)
    // @ts-expect-error _errors is not in the type definition
    consola.box(errors.map(([key, value]) => `${key}: ${value._errors.join(', ')}`).join('\n'))

    return {
      uid: schema._uid,
      title: '',
      label: '',
      category: '',
      gridRows: 1,
      gridType: 'product-grid-large',
      distributionsCount: 1,
      link: { cached_url: '', id: '' },
      ctaBackgroundColor: '',
      ctaTextColor: '',
      edgeStyle: 'none',
    }
  }
}

export function isProductListBlock(block: unknown): block is ProductListBlockContent {
  return (
    typeof block === 'object'
    && block !== null
    && (block as ProductListBlockContent).component === 'product-list-block'
  )
}
